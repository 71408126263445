<template>
  <nav class="site-navbar" :class="'site-navbar--' + navbarLayoutType">
    <div class="site-navbar__header">
      <h1 class="site-navbar__brand" @click="$router.push({ path: '/home' })">
        <a class="site-navbar__brand-lg" href="javascript:;">物联网管理系统</a>
        <a class="site-navbar__brand-mini" href="javascript:;">物联</a>
      </h1>
    </div>
    <div class="site-navbar__body clearfix">
      <el-menu
        class="site-navbar__menu"
        mode="horizontal">
        <el-menu-item class="site-navbar__switch" index="0" @click="sidebarFold = !sidebarFold">
          <i class="el-icon-s-fold" v-if="sidebarFold==false"></i>
          <i class="el-icon-s-unfold" v-if="sidebarFold==true"></i>
        </el-menu-item>
      </el-menu>

      <el-dropdown @command="handleCommand" class="userName" style="float:right;">
        <span class="userName">{{ userName }}<i class="el-icon-arrow-down el-icon--right"></i></span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="logoutHandle"><i class="el-icon-switch-button"></i>退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

    </div>
    <!-- 弹窗, 修改密码 -->
    <update-password v-if="updatePassowrdVisible" ref="updatePassowrd"></update-password>
  </nav>
</template>

<script>
  import UpdatePassword from './main-navbar-update-password'
  import { clearLoginInfo } from '@/utils'
  export default {
    data () {
      return {
        updatePassowrdVisible: false,
        userName: window.localStorage.getItem('userName')
      }
    },
    components: {
      UpdatePassword
    },
    computed: {
      navbarLayoutType: {
        get () { return this.$store.state.common.navbarLayoutType }
      },
      sidebarFold: {
        get () { return this.$store.state.common.sidebarFold },
        set (val) { this.$store.commit('common/updateSidebarFold', val) }
      },
      mainTabs: {
        get () { return this.$store.state.common.mainTabs },
        set (val) { this.$store.commit('common/updateMainTabs', val) }
      },

    },
    methods: {
      // 修改密码
      updatePasswordHandle () {
        this.updatePassowrdVisible = true
        this.$nextTick(() => {
          this.$refs.updatePassowrd.init()
        })
      },
      // 退出
      logoutHandle () {
        this.$confirm(`确定进行[退出]操作?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          window.localStorage.clear()
          this.$router.push('/')
        }).catch(() => {})
      },

      handleCommand(command){
        if(command=='logoutHandle'){
          this.logoutHandle()
        }
      },
    }
  }
</script>

<style>
.backEndPicture{
  cursor: pointer;
  width: 2%;
  float: right;
  margin-top: 1%;
  margin-right: 2%;
}
.headImg{
  float: right;
  width:2%;
  margin-top: 1%;
  margin-right: 2%;
}
.userName{

  font-size: 18px;
  color: white;
  margin-top: 1%;

  cursor: pointer;
}
.logoutButton{
  cursor:pointer;
  float:right;
  font-size: 15px;
  color: white;
  margin-top: 1%
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 10px;
  color: #8c939d;
  width: 45px;
  height: 45px;
  line-height: 200px;
  text-align: center;
}
.avatar {
  width: 145px;
  height: 145px;
  display: block;
}

.form_item{
  border: 1px solid lightgray;
  margin-left:5% ;
}

.site-navbar__menu :hover{
  background-color: #006ae3 !important;
}
.site-navbar__menu :focus{
  background-color: #006ae3 !important;
}

.title{
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px
}
</style>
