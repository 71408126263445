<template>
    <div class="dk-title-container">
        <span><b>{{ name }}</b></span>
    </div>
</template>
<script>
export default {
    name: 'dk-title',
    props: {
        name:{
            type: String,
            default: ''
        }
    },
    data(){
        return {

        }
    }
}
</script>
<style scoped>
.dk-title-container{
    float:left;
    width: 100%;
    height: auto; 
    padding-top: 10px;
    padding-bottom: 20px;
    font-size: 16px;
}
.dk-title-container span{
    border-left: 5px solid #0076F6;
    padding-left: 20px
}
</style>