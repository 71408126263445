<template>
    <el-dialog
        :visible.sync="visible"
        :title="propertyId==='' ? '新增属性' : '编辑属性'"
        width="800px">
        <el-alert
            title="添加自定义功能将影响设备通过语音、中控面板控制，建议添加标准功能，若已有标准功能无法满足，您可提交 意向单 申请新增标准功能。"
            type="warning"
            show-icon
            :closable="false">
        </el-alert>
        <el-form :model="form" ref="form" :rules="rules" label-width="100px">
            <el-form-item label="功能名称：" prop="name">
                <el-input
                    v-model="form.name"
                    placeholder="请输入名称">
                </el-input>
            </el-form-item>
            <el-form-item label="标识符：" prop="id">
                <el-input
                    v-model="form.id"
                    placeholder="请输入标识符"
                    @change="changeId">
                </el-input>
            </el-form-item>
            <!-- 数据定义start -->
            <el-form-item label="数据类型：" prop="define.type">
                <el-radio-group v-model="form.define.type" @input="handleTypeChange">
                    <el-radio-button
                        :label="item.value"
                        v-for="item in typeList"
                        :key="item.value"
                        >
                        {{ item.name }}
                    </el-radio-button>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="读写类型：" prop="mode">
                <el-radio-group v-model="form.mode">
                    <el-radio-button
                        :label="item.value"
                        v-for="item in modeList"
                        :key="item.value">
                        {{ item.name }}
                    </el-radio-button>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="数据定义：" prop="define.desc">
                {{form.define.desc}}
            </el-form-item>
            <el-form-item label="单位：" prop="define.unit" v-if="form.define.type==='int' || form.define.type==='float' || form.define.type==='timestamp'">
                <el-input
                    v-model="form.define.unit"
                    placeholder="请输入单位"
                    v-if="form.define.type==='int' || form.define.type==='float'">
                </el-input>
                <el-input
                    v-model="form.define.unit"
                    placeholder="请输入单位"
                    readonly
                    v-else>
                </el-input>
            </el-form-item>
            <!-- 数据定义end -->
            <el-form-item label="描述：" prop="desc">
                <el-input
                    type="textarea"
                    v-model="form.desc"
                    rows="6"
                    placeholder="请输入描述信息"
                    maxlength="80"
                    show-word-limit
                    >
                </el-input>
            </el-form-item>
            <div class="btns_container">
                <el-button class="bottom_btn" type="primary" size="medium" @click="handleConfirm('form')">保存</el-button>
                <el-button class="bottom_btn_cancel" size="medium" @click="handleCancel('form')">取消</el-button>
            </div>
        </el-form>
    </el-dialog>
</template>
<script>
export default {
    name: "import-model-dialog",
    props: {
        showDialog:{
            type: Boolean,
            default: false
        },
        modelData:{
            type: String,
            default: ''
        },
        propertyId:{
            type: String,
            default: ''
        }
    },
    data(){
        return {
            ID: '',
            model: {},
            form: {
                name: '', // 名称
                id:'', // 标识符
                desc:'', // 描述
                mode:'', // 读写类型
                define:{ // 数据定义
                    type:'', // 数据类型
                    unit:'', // 单位：int（整数型），float（浮点型）有单位且可修改，timestamp（时间型）单位默认秒，不可修改，其他类型单位为''
                    desc:''
                },
            },
            rules: {
                name:[
                    {required: true, message: '请输入功能名称', trigger: 'blur'}
                ],
                id:[
                    {required: true, message: '请输入标识符', trigger: 'blur'}
                ],
            },
            visible: this.showDialog,
            options: {
                line: true,
                theme: "rubyblue", // 主题
                tabSize: 4, // 制表符的宽度
                indentUnit: 2, // 一个块应该缩进多少个空格（无论这在编辑语言中意味着什么）。默认值为 2。
                firstLineNumber: 1, // 从哪个数字开始计算行数。默认值为 1。
                readOnly: false, // 只读
                autorefresh: true,
                smartIndent: true, // 上下文缩进
                lineNumbers: true, // 是否显示行号
                styleActiveLine: true, // 高亮选中行
                viewportMargin: Infinity, //处理高度自适应时搭配使用
                showCursorWhenSelecting: true, // 当选择处于活动状态时是否应绘制游标
                mode: "application/json",
                lineWrapping: true, //自动换行
                lint: false, // 开启语法检查
                gutters: ['CodeMirror-lint-markers'],  // 语法检查器
            },
            typeList:[{
                name: '布尔型',
                value: 'bool'
            },{
                name: '整数型',
                value: 'int'
            },{
                name: '浮点型',
                value: 'float'
            },{
                name: '时间型',
                value: 'timestamp'
            },{
                name: '字符串',
                value: 'string'
            }],
            modeList:[{
                name: '读写',
                value: 'rw'
            },{
                name: '只读',
                value: 'r'
            }],
            operator: localStorage.getItem('userName'),
            organization: JSON.parse(localStorage.getItem('organization')).organization
        }
    },
    methods: {
        changeId(val){
            console.log(val)
            console.log(this.form)
        },
        handleTypeChange(val){
            console.log(val)
            this.form.define.unit = val=='timestamp' ? '秒':''
        },
        submitForm(){
            console.log(this.form)
            console.log(this.model)
            let isChecked = false
            if(this.propertyId===''){
                let propertyList=this.model.properties.map(item=>item.id)
                console.log(propertyList)
                if(propertyList.includes(this.form.id)==true){
                    this.$message.error('该标识符已存在！')
                    isChecked = false
                }else{
                    this.model.properties.unshift(this.form)
                    isChecked = true
                }
                
            }else{
                let oldId = this.propertyId
                console.log(oldId)
                console.log(this.propertyId)
                console.log(this.form)
                if(this.form.id===oldId){ // 修改id的时候可以跟现在的id一样，直接覆盖
                    this.model.properties.forEach((item,index) => {
                        if(item.id===this.propertyId){
                            this.model.properties[index] = this.form
                        }
                    })
                    isChecked = true
                }else{
                    let propertyFilterList=this.model.properties.map(item=>item.id).filter(subItem=>subItem!==oldId)
                    if(propertyFilterList.includes(this.form.id)==true){
                        this.$message.error('该标识符已存在！')
                        isChecked = false
                    }else{
                        this.model.properties.forEach((item,index) => {
                            if(item.id===oldId){
                                this.model.properties[index] = this.form
                            }
                        })
                        isChecked = true
                    }
                    
                }
                
            }
            console.log('isChecked:'+isChecked)
            if(isChecked){
                console.log(this.model)
                let model = ''
                model = JSON.stringify(this.model)
                console.log(model)
                let data={
                    sql: `UPDATE product_table SET operator='${this.operator}',organization='${this.organization}',model='${model}' WHERE ID='${this.ID}'`,
                    type: 'update'
                }
                console.log(data)
                this.$axios.post('/sql/excute', data).then(res => {
                    if (res.data.code === 200) {
                        this.$message.success(this.propertyId!=='' ? '编辑属性成功！' : '添加属性成功！')
                        this.handleClose()
                        this.$emit('refresh')
                    } else {
                        this.$message.error(res.data.msg)
                    }
                }).catch(error => {
                    console.log('错误' + error)
                })
            }
            
        },
        /**
         * 表单提交按钮监听
         */
        handleConfirm(formName) {
            console.log(this.form)
            this.$refs[formName].validate((valid) => {
                console.log(valid)
                if (valid) {
                    this.submitForm();
                } else {
                    this.$message.warning('校验失败，请务必填写*标记内容')
                    return false
                }
            })
        },
        handleCancel(formName){
            this.handleClose()
        },
        /**
       * 关闭弹框
       */
      handleClose(){
        // this.initData();
        this.$refs['form'].resetFields()
        this.visible = false;
        this.$emit('close');
      },
    },
    watch:{
        showDialog(){
            this.visible = this.showDialog
        },
        visible(val, oldVal){
            if(!val){
                this.handleClose()
            }else{
                console.log('this.modelData watch:::')
                console.log(this.modelData=='{}')
                if(this.modelData=='{}'){ // 没有添加任何属性时，做初始化
                    this.model = {
                        properties:[]
                    }
                }else{
                    this.model = JSON.parse(this.modelData)
                }
                
                this.ID = this.$route.query.ID

                console.log(this.model)
                console.log(this.propertyId)
                if(this.propertyId!==''){
                    let property = JSON.parse(JSON.stringify(this.model)).properties.filter(item=>item.id===this.propertyId)[0]
                    console.log(property)
                    this.form = property
                }else{
                    this.form = {
                        name: '', // 名称
                        id:'', // 标识符
                        desc:'', // 描述
                        mode:'rw', // 读写类型
                        define:{ // 数据定义
                            type: 'bool', // 数据类型
                            unit:'', // 单位：int（整数型），float（浮点型）有单位且可修改，timestamp（时间型）单位默认秒，不可修改，其他类型单位为''
                            desc:''
                        },
                    }
                }
            }
        },
        'form.define.type':{
            handler(val, oldVal){
                if(val){
                    switch (val) {
                        case 'bool':
                            this.form.define.desc = '0 / 1'
                            break;
                        case 'int':
                            this.form.define.desc = '整数'
                            break;
                        case 'float':
                            this.form.define.desc = '浮点数'
                            break;
                        case 'timestamp':
                            this.form.define.desc = 'INT类型的UTC时间戳'
                            break;
                        case 'string':
                            this.form.define.desc = '字符串长度小于512字节'
                            break;
                        default:
                            break;
                    }
                    
                }
            },
            deep: true
        }
    }
}
</script>
<style scoped>
.btns_container{
    margin-top: 20px;
    text-align: center;
}
.tip-txt{
    font-size: 14px;
    margin: 20px 0px;
}
/*  */
</style>