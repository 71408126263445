<template>
    <div>
        <div class="tableBox">
            <dk-title name="新增设备"></dk-title>
            <el-form :model="deviceForm">
                <el-form-item label="设备类型">
                    <el-select v-model="deviceForm.deviceType" placeholder="请选择设备类型">
                        <el-option label="类型1" value="11"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="设备名称">
                    <el-input v-model="deviceName" placeholder="请输入设备名称" />
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>
<script>
import dkTitle from '../../components/dk-title.vue'
export default {
    name: "device-add",
    components: { dkTitle },
    data(){
        return {
            deviceForm: {
                deviceType: '',
                deviceName: ''
            }
        }
    },
    methods:{},
    mounted() {},
}
</script>
<style scoped>

</style>