<template>
    <el-dialog
        :visible.sync="visible"
        title="导入物模型"
        width="800px">
        <el-alert
            title="注意：导入新的JSON后原产品的物模型将会被覆盖"
            type="warning"
            show-icon
            :closable="false">
        </el-alert>
        <div class="tip-txt">您可以通过 JSON 对产品的物模型进行定义后导入平台，格式规范请查看文档</div>
        <el-form :model="form" ref="form" :rules="rules">
            <el-form-item label="JSON" prop="jsonContent">
                <!-- <el-input
                    type="textarea"
                    v-model="form.jsonContent"
                    rows="20"
                    placeholder="请将要导入的物模型对应的JSON粘贴到此文本框">
                </el-input> -->
                <codemirror
                    v-model="form.jsonContent" :options="options">
                </codemirror>
            </el-form-item>
            <div class="btns_container">
                <el-button class="bottom_btn" type="primary" size="medium" @click="handleConfirm('form')">导入</el-button>
                <el-button class="bottom_btn_cancel" size="medium" @click="handleCancel('form')">取消</el-button>
            </div>
        </el-form>
    </el-dialog>
</template>
<script>
export default {
    name: "import-model-dialog",
    props: {
        showDialog:{
            type: Boolean,
            default: false
        },
        ID:{
            type: String,
            default: ''
        },
    },
    data(){
        
        let checkJson = (rule, value, callback) => {
        if (value === '') {
            callback(new Error('请输入JSON字符串'));
        } else {
            try {
                JSON.parse(value);
                callback();
            } catch (e) {
                callback(new Error('输入不是有效的JSON'));
            }
        }
        };
        return {
            form: {
                jsonContent: ''
            },
            rules: {
                jsonContent:[
                    {required: true, validator: checkJson, message: '请输入JSON内容', trigger: 'blur'}
                ],
            },
            visible: this.showDialog,
            options: {
                line: true,
                theme: "rubyblue", // 主题
                tabSize: 4, // 制表符的宽度
                indentUnit: 2, // 一个块应该缩进多少个空格（无论这在编辑语言中意味着什么）。默认值为 2。
                firstLineNumber: 1, // 从哪个数字开始计算行数。默认值为 1。
                readOnly: false, // 只读
                autorefresh: true,
                smartIndent: true, // 上下文缩进
                lineNumbers: true, // 是否显示行号
                styleActiveLine: true, // 高亮选中行
                viewportMargin: Infinity, //处理高度自适应时搭配使用
                showCursorWhenSelecting: true, // 当选择处于活动状态时是否应绘制游标
                mode: "application/json",
                lineWrapping: true, //自动换行
                lint: false, // 开启语法检查
                gutters: ['CodeMirror-lint-markers'],  // 语法检查器
            },
            operator: localStorage.getItem('userName'),
            organization: JSON.parse(localStorage.getItem('organization')).organization
        }
    },
    methods: {
        submitForm(){
            console.log('提交表单')
            console.log(this.form.jsonContent)
            console.log(typeof(this.form.jsonContent))
            // console.log(JSON.stringify(this.form.jsonContent))
            // JSON.stringify(this.form.jsonContent).replace(/\t\n/g, '')
            let model = ''
            model = this.form.jsonContent.replace(/\n/g, '').replace(/\s+/g, '');
            let data={
                sql: `UPDATE product_table SET operator='${this.operator}',organization='${this.organization}',model='${model}' WHERE ID='${this.ID}'`,
                type: 'update'
            }
            console.log(data)
            this.$axios.post('/sql/excute', data).then(res => {
                if (res.data.code === 200) {
                    this.$message.success('导入物模型成功！')
                    this.handleClose()
                } else {}
            }).catch(error => {
                console.log('错误' + error)
            })
        },
        /**
         * 表单提交按钮监听
         */
        handleConfirm(formName) {
            console.log(this.form)
            this.$refs[formName].validate((valid) => {
                console.log(valid)
                if (valid) {
                    this.submitForm();
                } else {
                    this.$message.warning('校验失败，请务必填写*标记内容')
                    return false
                }
            })
        },
        handleCancel(formName){
            this.handleClose()
        },
        /**
       * 关闭弹框
       */
      handleClose(){
        // this.initData();
        this.$refs['form'].resetFields()
        this.visible = false;
        this.$emit('close');
      },
    },
    watch:{
        showDialog(){
            this.visible = this.showDialog
        },
        visible(val, oldVal){
            if(!val){
                this.handleClose()
            }else{
                
            }
        }
    }
}
</script>
<style scoped>
.btns_container{
    margin-top: 20px;
    text-align: center;
}
.tip-txt{
    font-size: 14px;
    margin: 20px 0px;
}
/*  */
</style>