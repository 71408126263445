<template>
    <el-dialog
        :visible.sync="visible"
        title="编辑产品信息"
        width="800px">
        <el-form :model="form" ref="form" :rules="rules" label-width="100px">
            <el-form-item label="产品类型：" :label-width="formLabelWidth" prop="type">
                <el-input
                    v-model="form.type"
                    placeholder="请输入产品类型">
                </el-input>
            </el-form-item>
            <el-form-item label="产品名称：" :label-width="formLabelWidth" prop="describes">
                <el-input
                    v-model="form.describes"
                    placeholder="请输入名称">
                </el-input>
            </el-form-item>
            <div class="btns_container">
                <el-button class="bottom_btn" type="primary" size="medium" @click="handleConfirm('form')">保存</el-button>
                <el-button class="bottom_btn_cancel" size="medium" @click="handleCancel('form')">取消</el-button>
            </div>
        </el-form>
    </el-dialog>
</template>
<script>
export default {
    name: "edit-product-dialog",
    props: {
        showDialog:{
            type: Boolean,
            default: false
        },
        info:{
            type: Object,
            default: () => {}
        }
    },
    data(){
        return {
            ID: '',
            model: {},
            form: {
                type: '', // 名称
                describes:'',
                ID:'',
            },
            rules: {
                describes:[
                    {required: true, message: '请输入名称', trigger: 'blur'}
                ],
                type:[
                    {required: true, message: '请输入类型', trigger: 'blur'}
                ],
            },
            visible: this.showDialog,
            operator: localStorage.getItem('userName'),
            organization: JSON.parse(localStorage.getItem('organization')).organization
        }
    },
    methods: {
        handleTypeChange(val){
            console.log(val)
            this.form.define.unit = val=='timestamp' ? '秒':''
        },
        submitForm(){
            console.log(this.form)
            let data={
                sql: `UPDATE product_table SET operator='${this.operator}',organization='${this.organization}',describes='${this.form.describes}', type='${this.form.type}' WHERE ID='${this.ID}'`,
                type: 'update'
            }
            console.log(data)
            this.$axios.post('/sql/excute', data).then(res => {
                if (res.data.code === 200) {
                    this.$message.success('编辑产品信息成功！')
                    this.handleClose()
                    this.$emit('refresh')
                } else {}
            }).catch(error => {
                console.log('错误' + error)
            })
        },
        /**
         * 表单提交按钮监听
         */
        handleConfirm(formName) {
            console.log(this.form)
            this.$refs[formName].validate((valid) => {
                console.log(valid)
                if (valid) {
                    this.submitForm();
                } else {
                    this.$message.warning('校验失败，请务必填写*标记内容')
                    return false
                }
            })
        },
        handleCancel(formName){
            this.handleClose()
        },
        /**
       * 关闭弹框
       */
      handleClose(){
        // this.initData();
        this.$refs['form'].resetFields()
        this.visible = false;
        this.$emit('close');
      },
    },
    watch:{
        showDialog(){
            this.visible = this.showDialog
        },
        visible(val, oldVal){
            if(!val){
                this.handleClose()
            }else{
                this.form = this.info
                this.ID = this.form.ID
            }
        }
    }
}
</script>
<style scoped>
.btns_container{
    margin-top: 20px;
    text-align: center;
}
.tip-txt{
    font-size: 14px;
    margin: 20px 0px;
}
/*  */
</style>