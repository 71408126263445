<template>
    <div>
        <dk-title name="产品详情"></dk-title>
        <el-card>
            <div slot="header">
                <span class="header-txt">{{ modelDetail.describes }} <span style="color: #0f0;">{{ modelDetail.status }}</span></span>
                <div style="float: right;">
                    <el-button type="text" @click="viewDetail">更多信息</el-button>
                </div>
            </div>
            <el-form :model="modelDetail">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="产品ID">
                            {{ modelDetail.ID }} <i class="el-icon-document-copy" style="cursor: pointer;" @click="copyContent(modelDetail.ID)" title="复制"></i>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="设备类型">
                            {{ modelDetail.type }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建时间">
                            {{ modelDetail.creattime | dateTimeFormat }}
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-steps :active="active" finish-status="success" simple>
            <el-step title="物模型定义" @click.native="changeTab(0)"></el-step>
            <el-step title="Topic列表" @click.native="changeTab(1)"></el-step>
            <el-step title="设备管理" @click.native="changeTab(2)"></el-step>
        </el-steps>
        <div v-show="active==0">
            <div style="margin: 20px 0;">
                <el-button type="primary" @click="handleImportModel">导入物模型</el-button>
                <el-button @click="handleViewModelJson">查看物模型JSON</el-button>
                <el-button style="float: right;" @click="viewJsonHelp">物模型定义帮助</el-button>
            </div>
            <el-divider></el-divider>
            <div>
                <el-button type="primary" @click="handleAddFunc">添加功能属性</el-button>
            </div>
            <el-table :data="funcList">
                <el-table-column label="功能名称" prop="name"></el-table-column>
                <el-table-column label="标识符" prop="id"></el-table-column>
                <el-table-column label="数据类型">
                    <template v-slot="scope">
                        {{scope.row.define.type | modelDataTypeFormat}}
                    </template>
                </el-table-column>
                <el-table-column label="读写类型">
                    <template v-slot="scope">
                        {{scope.row.mode | modeTypeFormat}}
                    </template>
                </el-table-column>
                <!-- <el-table-column label="数据定义" prop="define.desc"></el-table-column> -->
                <el-table-column label="操作" prop="">
                    <template v-slot="scope">
                        <el-button type="text" @click="handleEditFunc(scope.row)">编辑</el-button>
                        <el-button type="text" @click="handleDelFunc(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div v-show="active==1">
            <el-table :data="topicList" style="margin-top: 20px;" :header-cell-style="{background:'#F2F3F5', color:'#000000', fontFamily: 'Microsoft YaHei'}">
                <el-table-column label="Topic权限" prop="TopicName"></el-table-column>
                <el-table-column label="操作权限" prop="Privilege">
                    <template slot-scope="scope">
                        {{ scope.row.Privilege==1? "发布" : "订阅"}}
                    </template>
                </el-table-column>
                <el-table-column label="备注" prop="Comment"></el-table-column>
            </el-table>
        </div>
        <div v-show="active==2">
            <el-table
                :data="deviceList"
                style="margin-top: 20px;"
                :header-cell-style="{background:'#F2F3F5', color:'#000000', fontFamily: 'Microsoft YaHei'}"
                v-if="showDeviceDetail==false">
                <el-table-column label="sn序列号" prop="sn">
                    <template slot-scope="scope">
                        <span class="model-name" style="margin-left: 10px" @click="viewDeviceDetail(scope.row)">{{ scope.row.sn }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="设备名称" prop="describes"></el-table-column>
                <el-table-column label="状态" prop="">
                    <template slot-scope="scope">
                        <span :style="{'color': scope.row.isOnline===true ? '#0f0' : '#f00'}">{{ scope.row.isOnline===true ? '在线' : '离线' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="创建时间" prop="">
                    <template slot-scope="scope">
                        {{ scope.row.creattime | dateTimeFormat }}
                    </template>
                </el-table-column>
            </el-table>
            <div v-else>
                <div style="line-height: 36px;">
                    <i class="el-icon-back" style="color:#409EFF; cursor: pointer;" @click="viewDeviceList" title="返回设备列表"></i> {{ deviceInfo.describes }}
                </div>
                <el-tabs v-model="detailActiveName" type="card" @tab-click="handleClick">
                    <el-tab-pane label="设备信息" name="first">
                        <el-form v-model="deviceInfo" label-width="120px">
                            <el-form-item label="设备名称：" prop="describes">
                                {{ deviceInfo.describes }}
                            </el-form-item>
                            <el-form-item label="sn序列号：" prop="sn">
                                {{ deviceInfo.sn }}
                            </el-form-item>
                            <el-form-item label="产品ID：" prop="productID">
                                {{ deviceInfo.productID }}
                            </el-form-item>
                            <el-form-item label="创建时间：" prop="creattime">
                                {{ deviceInfo.creattime | dateTimeFormat }}
                            </el-form-item>
                            <el-form-item label="当前状态：" prop="">
                                {{ currentValue }}
                            </el-form-item>
                        </el-form>
                    </el-tab-pane>
                    <el-tab-pane label="历史数据" name="second">
                        <div>
                            <el-date-picker
                                v-model="dateDuration"
                                style="margin-left: 20px"
                                type="datetimerange"
                                :picker-options="pickerOptions"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                align="right"
                                size="small"
                                :default-time="['00:00:00', '23:59:59']">
                            </el-date-picker>
                            <el-button type="primary" size="small" style="margin-left: 20px" @click="getHistoryList(dateDuration)">搜索</el-button>
                        </div>
                        <el-table :default-sort = "{prop: 'time', order: 'descending'}" :data="historyList" style="width: 100%; margin-top: 20px" :header-cell-style="{background:'#F2F3F5', color:'#000000', fontFamily: 'Microsoft YaHei'}">
                            <el-table-column
                                align="center"
                                prop="time"
                                label="时间"
                                sortable
                                >
                                <template slot-scope="scope">
                                    {{scope.row.time | format('YYYY-MM-DD HH:mm:ss')}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                align="center"
                                prop="value"
                                label="值"
                                >
                                <template slot-scope="scope">
                                    <span style="width: 100%">{{scope.row.value}}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                </el-tabs>
                
            </div>
        </div>
        <import-model-dialog
            :showDialog="importModelDialog"
            :ID="modelDetail.ID"
            @close="hideDialog">
        </import-model-dialog>
        <view-model-dialog
            :showDialog="viewModelJsondialog"
            :modelData="modelDetail.model"
            @close="hideDialog">
        </view-model-dialog>
        <edit-function-dialog
            :showDialog="viewEditFunctionDialog"
            :modelData="modelDetail.model"
            :propertyId="propertyId"
            @close="hideDialog"
            @refresh="getModelDetail">
        </edit-function-dialog>
        <view-product-detail-drawer
            :showDialog="viewModelDetailDrawer"
            :info="modelDetail"
            @close="hideDialog"
            @edit="viewProductDialog=true">
        </view-product-detail-drawer>
        <edit-product-dialog
            :showDialog="viewProductDialog"
            :info="modelDetail"
            @close="viewProductDialog=false"
            @refresh="getModelDetail">
        </edit-product-dialog>
    </div>
</template>
<script>
import dkTitle from '../../components/dk-title.vue'
import ImportModelDialog from './components/importModelDialog.vue'
import ViewModelDialog from './components/viewModelDialog.vue'
import EditFunctionDialog from './components/editFunctionDialog.vue'
import ViewProductDetailDrawer from './components/viewProductDetailDrawer.vue'
import EditProductDialog from './components/editProductDialog.vue'

export default {
    name: 'model-detail',
    components: { dkTitle, ImportModelDialog, ViewModelDialog, EditFunctionDialog, ViewProductDetailDrawer, EditProductDialog },
    data(){
        return {
            currentValue: '',
            dateDuration:[new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()-1), new Date()], // 初始值24小时内
            pickerOptions:{
                shortcuts: [{
                text: '最近一周',
                onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', [start, end]);
                }
                }, {
                text: '最近一个月',
                onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    picker.$emit('pick', [start, end]);
                }
                }, {
                text: '最近三个月',
                onClick(picker) {
                    const end = new Date();
                    const start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    picker.$emit('pick', [start, end]);
                }
                }]
            },
            historyList:[],
            deviceInfo: {},
            showDeviceDetail: false, // 是否显示设备详情：true 显示设备详情，false 显示设备列表
            detailActiveName: 'first',
            modelDetail: {},
            ID: '',
            active: 0,
            importModelDialog: false,
            viewModelJsondialog: false,
            viewEditFunctionDialog: false,
            viewModelDetailDrawer: false,
            viewProductDialog: false,
            topicList: [{
                TopicName: "$thing/up/property/WTBG0PTGSI/${deviceName}",
                Comment: "属性上报",
                Privilege: 1
            },{
                TopicName: "$thing/down/property/WTBG0PTGSI/${deviceName}",
                Comment: "属性下发与属性上报响应",
                Privilege: 2
            },{
                TopicName: "$thing/up/event/WTBG0PTGSI/${deviceName}",
                Comment: "事件上报",
                Privilege: 1
            }],
            deviceList:[],
            deviceOnlineList:[],
            funcList: [{
                type: '属性'
            }],
            operator: localStorage.getItem('userName'),
            organization: JSON.parse(localStorage.getItem('organization')).organization,
            propertyId: '', // 属性id
        }
    },
    methods: {
        viewJsonHelp(){
            this.$router.push('/help')
        },
        viewDeviceList(){
            this.showDeviceDetail = false
            this.getDeviceList()
        },
        //获取最新的时序数据
        getHistoryNewest(){
            let config={
                headers: {
                'Content-Type':'application/vnd.flux',
                'Authorization':'Token kTadiNxM2KlR_ajGhRd1x3tTVbafTW1HyLnZ_tzlAB8M7ytnQNhYbfDCzs9VXAhU4hseBYkpdokRzi2AN9vnhA==',
                'Accept':'application/csv'
                }
            }
            let data=`from(bucket: "dkzn")|> range(start:0)|> filter(fn: (r) => r._measurement == "${this.deviceInfo.productID}")|> filter(fn: (r) => r.sn == "${this.deviceInfo.sn}")`
            this.$axios.post('/v2/query?org=DKZN', data, config).then(res => {
                let historyList=[]
                let row=res.data.split('\n')
                console.log(row)
                for(var i = 1; i < row.length-2; i++){ // 第一行为字段名，去除，返回的数据最后存在两个空行，所以要row.length-2
                    console.log("row[i].split(',')")
                    console.log(row[i].split(','))

                    let obj={time:'', value:''}
                    obj.time=row[i].split(',')[5]
                    obj.value=this.getFormatValue(row[i])

                    historyList.push(obj)
                }
                this.currentValue = historyList && historyList.length>0 ? historyList[0].value : '-'
            }).catch(error => {
                console.log('错误' + error)
            })
        },
        //获取时间范围内的时序数据
        getHistoryList(dateDuration){
            console.log(dateDuration)
            let config={
                headers: {
                'Content-Type':'application/vnd.flux',
                'Authorization':'Token kTadiNxM2KlR_ajGhRd1x3tTVbafTW1HyLnZ_tzlAB8M7ytnQNhYbfDCzs9VXAhU4hseBYkpdokRzi2AN9vnhA==',
                'Accept':'application/csv'
                }
            }

            let searchTimeRange = []
            if(dateDuration && dateDuration.length>0){
                searchTimeRange = `start:${new Date(dateDuration[0]).toJSON()}, stop: ${new Date(dateDuration[1]).toJSON()}`
            }else{
                searchTimeRange = "start:0"
            }
            let data=`from(bucket: "dkzn")|> range(${searchTimeRange})|> filter(fn: (r) => r._measurement == "${this.deviceInfo.productID}")|> filter(fn: (r) => r.sn == "${this.deviceInfo.sn}")`
            this.$axios.post('/v2/query?org=DKZN', data, config).then(res => {
                this.historyList=[]
                let row=res.data.split('\n')
                console.log(row)
                for(var i = 1; i < row.length-2; i++){ // 第一行为字段名，去除，返回的数据最后存在两个空行，所以要row.length-2
                    console.log("row[i].split(',')")
                    console.log(row[i].split(','))

                    let obj={time:'', value:''}
                    obj.time=row[i].split(',')[5]
                    obj.value=this.getFormatValue(row[i])

                    this.historyList.push(obj)
                }
            }).catch(error => {
                console.log('错误' + error)
            })
        },
        // 获取历史数据中的value为对象时的值，存在多个逗号分割问题
        getFormatValue(obj){
            const regex = /"{([^}]+)}"/;
            return '{'+obj.match(regex)[1].replace(/""/g, '"')+'}'
        },
        handleClick(tab, event) {
            console.log(tab, event);
            console.log(this.detailActiveName)
            if(this.detailActiveName==='second'){
                this.getHistoryList(this.dateDuration)
            }else{
                this.getHistoryNewest()
            }
        },
        // 查看设备详情
        viewDeviceDetail(row){
            this.showDeviceDetail = true
            this.detailActiveName = 'first'
            this.historyList = []
            let sn = row ? row.sn : this.$route.query.sn
            let data={
                "sql": `SELECT * FROM device_table where productID='${this.ID}' && sn='${sn}'`,
                "type": "select"
            }
            this.$axios.post('/sql/excute', data).then(res => {
                if(res.data.code===200){
                    this.deviceInfo = res.data.data[0] || {}
                    this.getHistoryNewest()
                }
            }).catch(error => {
                console.log('错误' + error)
            })
        },
        // 查看产品详情
        viewDetail(){
            this.viewModelDetailDrawer = true
        },
        // 编辑属性
        handleEditFunc(row){
            this.viewEditFunctionDialog = true
            console.log(row.id)
            this.propertyId = row.id
        },
        /**
         * 
         * @param model 模型属性properties的数据
         * @param operate 操作名称,中文
         */
        handleUpdate(model, operate){
            let data={
                sql: `UPDATE product_table SET operator='${this.operator}',organization='${this.organization}',model='${model}' WHERE ID='${this.ID}'`,
                type: 'update'
            }
            console.log(data)
            this.$axios.post('/sql/excute', data).then(res => {
                if (res.data.code === 200) {
                    this.$message.success(`属性${operate}成功！`)
                    this.getModelDetail()
                } else {}
            }).catch(error => {
                console.log('错误' + error)
            })
        },
        // 删除属性
        handleDelFunc(row){
            this.$confirm('确定删除该属性吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    let newFuncList = this.funcList.filter(item=>item.id !== row.id) // 删除属性
                    console.log(newFuncList)
                    console.log(this.modelDetail)
                    let model={
                        "properties": newFuncList
                    }
                    this.handleUpdate(JSON.stringify(model), "删除")
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '取消删除'
                    });          
                });
        },
        // 复制内容
        copyContent(val){
            navigator.clipboard
                .writeText(val)
                .then(()=>{
                    this.$message.success('复制成功')
                })
                .catch((err) => {
                    this.$message.error('复制成功')
                })
        },
        handleAddFunc(){
            console.log('添加标准功能~')
            this.viewEditFunctionDialog = true
            this.propertyId = ''
        },
        hideDialog(){
            this.importModelDialog = false
            this.viewModelJsondialog = false
            this.viewEditFunctionDialog = false
            this.viewModelDetailDrawer = false
        },
        handleImportModel(){
            this.importModelDialog = true
        },
        handleViewModelJson(){
            this.viewModelJsondialog = true
        },
        getModelDetail(){
            let queryCondition = ''
            queryCondition = "where ID='"+this.ID+"'"

            let data={
                "sql": `SELECT * FROM product_table ${queryCondition}`,
                "type": "select"
            }
            this.$axios.post('/sql/excute', data).then(res => {
                if (res.data.code === 200) {
                    console.log(res.data.data)
                    this.modelDetail = res.data.data[0] || {}
                    this.funcList = JSON.parse(this.modelDetail.model).properties
                } else {}
                }
            ).catch(error => {
                console.log('错误' + error)
            })
        },
        changeTab(val){
            console.log('changeTab~'+val)
            this.active = val
            console.log(this.active)
            if(val===2){// 设备管理
                this.showDeviceDetail = false
                this.getDeviceList()
            }
        },
        // 获取设备列表
        getDeviceList(){
            let queryCondition = ''
            // queryCondition =  this.searchData ? "where describes like "+"'%"+this.searchData+"%'" : ""
            queryCondition = "where ID="+this.ID

            let data={
                "sql": `SELECT * FROM device_table where productID='${this.ID}'`,
                "type": "select"
            }
            this.$axios.post('/sql/excute', data).then(res => {
                if(res.data.code===200){
                    console.log(res.data.data)
                    this.deviceList = res.data.data || []
                    this.getDeviceOnlineList()
                }
            }).catch(error => {
                console.log('错误' + error)
            })
        },
        // 设备列表中用于与列表接口比对后设备状态的获取
        getDeviceOnlineList(){
            let config={
                headers: {
                'Content-Type':'application/json',
                'Authorization':'Basic ZjExZDI4Y2I0OGNmZjQ2NzpyY1ZpYTZRcFE0VWZDb0lUSzhaUVVyRzlBemFWUk1UQnowTXk3cEZld1E5Q0o='
                }
            }
            this.$axios.get('/v5/clients', config).then(res => {
                console.log('在线设备')
                console.log(res.data)
                console.log(res.data.data)
                
                this.deviceOnlineList = res.data.data.map(item=>item.clientid)
                console.log(this.deviceOnlineList)
                this.deviceList.forEach(item=>{
                    item.isOnline = this.deviceOnlineList.includes(item.sn)
                })
                console.log('获取在线状态后的数组')
                console.log(this.deviceList)
            }).catch(error => {
                console.log('错误' + error)
            })
        }
    },
    mounted() {
        console.log(this.$route.query)
        let query= this.$route.query
        this.ID = query.ID
        this.getModelDetail()
        this.active = query.step ? Number(query.step) : 0
        console.log("路由获取的sn：" + query.sn)
        if(query.sn && query.sn!==''){
            this.showDeviceDetail = true
            this.detailActiveName = 'first'
            this.viewDeviceDetail()
        }
    },
    watch:{
        // '$route.query':{
        //     immediate: true,
        //     handler(val, oldVal){
        //         console.log(val)
        //         // console.log( val.ID)
        //         this.ID = val.ID
        //         this.getModelDetail()
        //         this.active = val.step ? Number(val.step) : 0
        //         if(val.sn){
        //             this.showDeviceDetail = true
        //             this.detailActiveName = 'first'
        //             this.viewDeviceDetail()
                    
        //         }
        //     }
        // },
    }
}
</script>
<style scoped>
.header-txt{
  font-weight: 700;
  font-size: 14px;
}
.model-name{
  color: #409EFF;
}
.model-name:hover{
  color: #409EFF;
  text-decoration: underline;
  cursor: pointer;
}
</style>