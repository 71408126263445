<template>
<div>
  <div class="tableBox">
    <div style="width: 100%; height: auto; padding-top: 10px; padding-bottom: 20px; font-size: 16px;">
      <span style="border-left: 5px solid #0076F6; padding-left: 20px"><b>首页</b></span>
    </div>
    <el-card>
      <div slot="header">
        <span class="header-txt">常用功能</span>
      </div>
      <div class="func-container">
        <div class="item icon-device-manage" @click="goPage('/deviceManage',false)">
          <div class="name">设备管理</div>
          <div class="desc">对设备进行管理</div>
        </div>
        <div class="item icon-product-develop" @click="goPage('/modelManage',false)">
          <div class="name">产品管理</div>
          <div class="desc">创建产品将设备接入平台</div>
        </div>
        <div class="item icon-rules-engine" @click="goPage('http://114.67.109.131:18083/#/rules', true)">
          <div class="name">规则管理</div>
          <div class="desc">设备消息转发至用户业务系统</div>
        </div>
      </div>
    </el-card>
    <el-card style="margin-top: 20px;">
      <div slot="header">
        <span class="header-txt">实例信息</span>
      </div>
      <el-form :model="exampleForm" ref="exampleForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="实例ID" prop="ID">
              {{exampleForm.ID}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="组织名称" prop="organization">
              {{exampleForm.organization}}
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="实例备注" prop="describe">
              {{exampleForm.describe}}
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="创建时间" prop="creattime">
              {{exampleForm.creattime | dateTimeFormat}}
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <el-card style="margin-top: 20px;">
      <div slot="header">
        <span class="header-txt">资源状态</span>
      </div>
      <el-row>
        <el-col :span="12">
          <div class="resource-status-title">
            已创建设备数
          </div>
          <div class="resource-value">
            <span>{{ deviceNum }}</span> 个
          </div>
        </el-col>
        <el-col :span="12">
          <div class="resource-status-title">
            产品总数
          </div>
          <div class="resource-value">
            <span>{{ productNum }}</span> 个
          </div>
        </el-col>
      </el-row>
    </el-card>
  </div>
</div>
</template>

<script>
export default {
  name:'home',
  data(){
    return{
      exampleForm: {
        ID: '',
        describe: '',
        organization: '',
        creattime: ''
      },
      deviceNum: 0,
      productNum: 0
    }
  },

  mounted() {
    this.getBaseInfo()
    this.getDeviceNum()
    this.getProductNum()
  },

  methods:{
    //获取产品总数
    getProductNum(){
      let data={
        "sql": `SELECT * FROM product_table`,
        "type": "select"
      }
      this.$axios.post('/sql/excute', data).then(res => {
          if (res.data.code === 200) {
            this.productNum=res.data.data.length
          } else {}
        }
      ).catch(error => {
        console.log('错误' + error)
      })
    },
    // 已创建设备数
    getDeviceNum(){
      let data={
        "sql": `SELECT * FROM device_table`,
        "type": "select"
      }
      this.$axios.post('/sql/excute', data).then(res => {
        if(res.data.code===200){
          this.deviceNum = res.data.data.length
        }
      }).catch(error => {
        console.log('错误' + error)
      })
    },
    goPage(url, isThirdPage){
      if(isThirdPage==true){
        window.open(url, '_blank')
      }else{
        this.$router.push(url)
      }
      
    },
    // 实例信息
    getBaseInfo(){
      let data={
        "sql": "SELECT * FROM base_table",
        "type": "select"
      }
      this.$axios.post('/sql/excute', data).then(res => {
        if(res.data.code===200){
          this.exampleForm = res.data.data[0]
          let organization = JSON.stringify(res.data.data[0]) || ""
          localStorage.setItem('organization', organization)
        }
      }).catch(error => {
        console.log('错误' + error)
      })
    },
  },
}
</script>

<style scoped>
.func-container{
  display: flex;
}
.func-container .item{
  flex: 1;
  height: 78px;
  margin: 10px;
  padding: 10px;
  background: url('https://qcloudimg.tencent-cloud.cn/raw/c0943c477028a68eacacb1e57cb5f77b.png') no-repeat right;
  background-size: 100px 82px;
  box-shadow: 0 2px 4px 0 rgba(54,58,80,.32);
  cursor: pointer
}
.func-container .item .name{
  font-weight: bold;
  font-size: 16px;
}
.func-container .item .desc{
  font-size: 12px;
  margin-top: 4px;
}
.icon-device-manage{
  background: url('https://qcloudimg.tencent-cloud.cn/raw/c0943c477028a68eacacb1e57cb5f77b.png') no-repeat right;
}
.icon-product-develop{
  background: url('https://qcloudimg.tencent-cloud.cn/raw/c0943c477028a68eacacb1e57cb5f77b.png') no-repeat right;
}
.icon-rules-engine{
  background: url('https://qcloudimg.tencent-cloud.cn/raw/c0943c477028a68eacacb1e57cb5f77b.png') no-repeat right;
}

.resource-status-title{
  color: rgba(0,0,0,.6);
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 10px;
}
.resource-value{
  color: #000;
  font-size: 14px;
}
.resource-value span{
  font-size: 36px;
  color: #000;
}
.header-txt{
  font-weight: 700;
  font-size: 14px;
}
</style>
