<template>
<div>
  <div class="tableBox">
    <dk-title name="设备列表"></dk-title>
    <el-button
      style="float: right; background-color: #0076F6; color: #fff"
      size="mini"
      @click="openAddDialog"
      icon="el-icon-plus">
      新增
    </el-button>
    <el-form :model="searchData" inline ref="searchForm">
      <el-form-item label="sn序列号：" prop="sn">
        <el-input style="width: 200px" size="mini" placeholder="支持模糊查询" v-model="searchData.sn" clearable></el-input> 
      </el-form-item>
      <el-form-item label="设备名称：" prop="describes">
        <el-input style="width: 200px" size="mini" placeholder="支持模糊查询" v-model="searchData.describes" clearable></el-input> 
      </el-form-item>
      <el-form-item>
        <el-button style="margin-left: 20px; background-color: #0076F6; color: #fff" size="mini" @click="getDeviceList" icon="el-icon-search">搜索</el-button>
        <el-button style="margin-left: 20px;" size="mini" @click="resetSearch('searchForm')">重置</el-button>
      </el-form-item>
    </el-form>
    
    <div class="table" style="height: 700px; overflow: auto">
      <el-table :default-sort = "{prop: 'id', order: 'ascending'}" :data="list" style="width: 100%; margin-top: 20px" :header-cell-style="{background:'#F2F3F5', color:'#000000', fontFamily: 'Microsoft YaHei'}">
        <el-table-column
          show-overflow-tooltip
          align="left"
          prop="sn"
          label="sn序列号"
          sortable
          fit>
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.sn }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="left"
          prop="describes"
          label="设备名称"
          sortable
          >
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.describes }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="left"
          prop="productID"
          label="所属产品ID"
          sortable
          fit>
          <template slot-scope="scope">
            <span class="model-name" style="margin-left: 10px" @click="goModelDetail(scope.row)">{{ scope.row.productID }}</span>
          </template>
        </el-table-column>
        
        <el-table-column
          align="left"
          prop=""
          label="状态"
          sortable
          fit>
          <template slot-scope="scope">
            <span :style="{'color': scope.row.isOnline ? '#10d810' : '#f00'}">{{ scope.row.isOnline ? '在线' : '离线' }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column
          align="center"
          fixed
          prop="position"
          label="位置"
          sortable
          fit>
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.describes.split('-')[1] }}</span>
          </template>
        </el-table-column> -->
      
      <!-- <el-table-column
        align="center"
        prop="model"
        label="关联模型"
        sortable
        fit>
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{ scope.row.model }}</span>
        </template>
      </el-table-column> -->
      <el-table-column
        align="left"
        prop="operation"
        label="操作"
        fixed="right"
        fit>
        <template slot-scope="scope">
          <span style="color: rgba(255,0,0,0.71); cursor: pointer; font-size: 12px; margin-right: 10%" @click="deviceDelete(scope.$index, scope.row)">删除</span>
          <span style="color: #409EFF; cursor: pointer; font-size: 12px; margin-right: 10%" @click="openInfoEditDialog(scope.$index, scope.row)">编辑</span>
          <!-- <span style="color: #409EFF; cursor: pointer; font-size: 12px; margin-right: 10%" @click="openDetailDialog(scope.$index, scope.row)">查看</span> -->
        </template>
      </el-table-column>
    </el-table>
  </div>
  </div>

  <!--  新增设备-->
  <el-dialog
    append-to-body
    title="新增设备"
    :visible.sync="addDialog"
    width="30%">
    <el-form :model="addForm" ref="addForm" :rules="addFormRules">
      <el-form-item label="设备类型" :label-width="formLabelWidth" prop="type">
        <el-input v-model="addForm.type" size="mini" style="width: 250px"></el-input>
      </el-form-item>
      <el-form-item label="设备名称" :label-width="formLabelWidth" prop="name">
        <el-input v-model="addForm.name" size="mini" style="width: 250px"></el-input>
      </el-form-item>
      <el-form-item label="sn序列号" :label-width="formLabelWidth" prop="sn">
        <el-input v-model="addForm.sn" maxlength="30" size="mini" style="width: 250px"></el-input>
      </el-form-item>
      <el-form-item label="位置" :label-width="formLabelWidth" prop="position">
        <el-input v-model="addForm.position" size="mini" style="width: 250px"></el-input>
      </el-form-item>
      <el-form-item label="所属产品" :label-width="formLabelWidth" prop="productID">
        <el-select v-model="addForm.productID" placeholder="请选产品" size="mini" style="width: 250px">
          <el-option
            v-for="(item,index) in productList" :key="index"
            :label="item.describes" :value="item.ID">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="mini" @click="addDialog = false">取 消</el-button>
      <el-button size="mini" type="primary"  @click="confirmAdd('addForm')">确 定</el-button>
    </span>
  </el-dialog>

  <!--  设备信息编辑弹框-->
  <el-dialog
    append-to-body
    title="设备编辑"
    :visible.sync="infoEditDialog"
    width="30%">
    <el-form :model="infoEditForm" ref="infoEditForm">
      <el-form-item label="sn" :label-width="formLabelWidth" prop="sn" v-if="show">
        <el-input v-model="infoEditForm.sn" size="mini" style="width: 200px"></el-input>
      </el-form-item>
      <el-form-item label="设备类型" :label-width="formLabelWidth" prop="type">
        <el-input v-model="infoEditForm.type" size="mini" style="width: 200px"></el-input>
      </el-form-item>
      <el-form-item label="设备名称" :label-width="formLabelWidth" prop="name">
        <el-input v-model="infoEditForm.name" size="mini" style="width: 200px"></el-input>
      </el-form-item>
      <el-form-item label="位置" :label-width="formLabelWidth" prop="position">
        <el-input v-model="infoEditForm.position" size="mini" style="width: 200px"></el-input>
      </el-form-item>
      <el-form-item label="所属产品" :label-width="formLabelWidth" prop="productID">
        <el-select v-model="infoEditForm.productID" placeholder="请选产品" size="mini" style="width: 200px">
          <el-option
            v-for="(item,index) in productList" :key="index"
            :label="item.describes" :value="item.ID">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="mini" @click="infoEditDialog = false">取 消</el-button>
      <el-button size="mini" type="primary"  @click="infoEditButton()">确 定</el-button>
    </span>
  </el-dialog>

  <!--    设备详情-->
  <el-dialog
    append-to-body
    title="设备详情"
    :visible.sync="detailDialog">
    <el-form :model="detailForm" ref="detailForm" label-position="left">
      <el-row>
        <el-col :span="8">
          <el-form-item label="当前设备:" label-width="80px" prop="describes">
            <span style="width: 80%">{{detailForm.describes}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="sn序列号:" label-width="80px" prop="sn">
            <span style="width: 80%">{{detailForm.sn}}</span>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="关联模型:" label-width="80px" prop="model">
            <span style="width: 80%">{{detailForm.model}}</span>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </el-dialog>
</div>
</template>

<script>
import dkTitle from '../../components/dk-title.vue'
export default {
  name:'device-manage',
  components: { dkTitle },
  data(){
    return{
      show: false,
      list: [],
      // searchData: '',
      searchData: {
        sn: '',
        describes:''
      },
      formLabelWidth: '120px',
      editDialog: false,
      editForm: {
        describes:'',
        sn:'',
      },

      addDialog: false,
      addForm: {
        type:'',
        name:'',
        sn:'',
        position:'',
        productID:''
      },
      addFormRules: {
        sn: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
      },

      detailDialog: false,
      detailForm: {
        describes: '',
        sn: '',
        model: ''
      },
      infoEditDialog:false,
      infoEditForm:{
        sn:'',
        name:'',
        type:'',
        position:'',
        productID:''
      },
      deviceOnlineList: [], // 设备列表（在线状态）
      organization: JSON.parse(localStorage.getItem('organization')).organization || '', // 所属组织
      productList:[]
    }
  },

  mounted() {
    console.log(this.organization)
    this.getDeviceList()
  },

  methods:{
    // 重置搜索条件
    resetSearch(formName){
      this.$refs[formName].resetFields();
    },
    goModelDetail(row){
      console.log(row)
      this.$router.push({
        path: '/modelDetail',
        query: {
          ID: row.productID,
          step: 2,
          sn: row.sn
        }
      })
    },
    //获取产品列表
    getModelList(){
      let queryCondition = ''

      let data={
        "sql": `SELECT * FROM product_table`,
        "type": "select"
      }
      this.$axios.post('/sql/excute', data).then(res => {
          if (res.data.code === 200) {
            this.productList=res.data.data
          } else {}
        }
      ).catch(error => {
        console.log('错误' + error)
      })
    },
    // 设备列表中用于与列表接口比对后设备状态的获取
    getDeviceOnlineList(){
      let config={
        headers: {
          'Content-Type':'application/json',
          'Authorization':'Basic ZjExZDI4Y2I0OGNmZjQ2NzpyY1ZpYTZRcFE0VWZDb0lUSzhaUVVyRzlBemFWUk1UQnowTXk3cEZld1E5Q0o='
        }
      }
      this.$axios.get('/v5/clients', config).then(res => {
          console.log('在线设备')
          console.log(res.data)
          console.log(res.data.data)
          this.deviceOnlineList = res.data.data.map(item=>item.clientid)
          console.log(this.deviceOnlineList)
          this.list.forEach(item=>{
            this.$set(item, 'isOnline', this.deviceOnlineList.includes(item.sn)) // 添加属性动态相应
          })
          console.log('获取在线状态后的数组')
          console.log(this.list)
      }).catch(error => {
        console.log('错误' + error)
      })
    },
    // 新增设备
    handleAdd(){
      this.$router.push({
        path: '/deviceAdd'
      })
    },
    getDeviceList(){
      let query1 = ''
      let query2 = ''
      let query=''
      query1 =  this.searchData.sn ? "sn like "+"'%"+this.searchData.sn+"%'" : ""
      query2 =  this.searchData.describes ? "describes like "+"'%"+this.searchData.describes+"%'" : ""
      if(query1!=='' && query2 !== ''){
        query = 'where '+query1+ ' && ' + query2
      }else if(query1!=='' && query2 == ''){
        query = 'where '+query1
      }else if(query1=='' && query2 !== ''){
        query = 'where '+query2
      }else{
        query = ''
      }
      console.log(query)
      let data={
        "sql": `SELECT * FROM device_table ${query} order by creattime desc`,
        "type": "select"
      }
      this.$axios.post('/sql/excute', data).then(res => {
        if(res.data.code===200){
          this.list = res.data.data || []
          this.getDeviceOnlineList()
        }
      }).catch(error => {
        console.log('错误' + error)
      })
    },

    //打开新增弹框
    openAddDialog() {
      this.addForm.type=''
      this.addForm.name=''
      this.addForm.sn=''
      this.addForm.position=''
      this.addForm.productID=''
      this.addDialog=true
      this.getModelList()
    },
    
    confirmAdd(formName){
      let data = {
        "sql": "INSERT INTO device_table (sn,describes,operator,organization,productID)VALUES("+"'"+this.addForm.sn+"'"+","+"'"+this.addForm.type+"-"+this.addForm.position+"-"+this.addForm.name+"'"+","+"'"+localStorage.getItem('userName')+"'"+",'"+this.organization+"','"+this.addForm.productID+"')",
        "type": "insert"
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios.post('/sql/excute', data).then(res => {
              if (res.data.code === 200) {
                this.addDialog=false
                this.getDeviceList()
              }else if(res.data.msg.indexOf('Duplicate')!=-1){
                this.$message.error('该sn序列号已存在')
              } else {}  
            }
          ).catch(error => {
            console.log(error)
          })
        } else {
          return false;
        }
      })
    },

    //打开设备信息编辑弹框
    openInfoEditDialog(index, row){
      this.infoEditDialog=true
      this.infoEditForm.sn=row.sn
      this.infoEditForm.name=row.describes.split('-')[2]
      this.infoEditForm.type=row.describes.split('-')[0]
      this.infoEditForm.position=row.describes.split('-')[1]
      this.infoEditForm.productID=row.productID
      this.getModelList()
    },
    infoEditButton(){
      let data={
        "sql": `UPDATE device_table SET describes='${this.infoEditForm.type}-${this.infoEditForm.position}-${this.infoEditForm.name}',sn='${this.infoEditForm.sn}',productID='${this.infoEditForm.productID}' WHERE sn='${this.infoEditForm.sn}'`,
        "type": "update"
      }
      this.$axios.post('/sql/excute', data).then(res => {
        if(res.data.code==200){
          this.infoEditDialog=false
          this.getDeviceList()
        }
        }
      ).catch(error => {
        console.log('错误' + error)
      })
    },

    //打开详情弹框
    openDetailDialog(index, row){
      this.$router.push({
        path: '/deviceDetail',
        query: {
          sn: row.sn
        }
      })
      // this.detailForm.describes=row.describes.split('-')[2]
      // this.detailForm.sn=row.sn
      // this.detailForm.model=row.model
      // this.detailDialog=true
    },

    //设备删除
    deviceDelete(index, row){
      this.$confirm("永久删除此项, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          let data={
            "sql": "DELETE FROM device_table WHERE sn="+"'"+row.sn+"'",
            "type": "delete"
          }
          this.$axios.post('/sql/excute', data).then(res => {
              if (res.data.code === 200) {
                this.$message.success('删除成功')
                this.getDeviceList()
              } else {}
            }
          ).catch(error => {
            console.log('错误' + error)
          })
        }).catch(err => {
        this.$message.error('服务器失败' + err)
      })
    },

  },
}
</script>

<style scoped>
.model-name{
  color: #409EFF;
}
.model-name:hover{
  color: #409EFF;
  text-decoration: underline;
  cursor: pointer;
}
</style>