import Vue from 'vue'
import Vuex from 'vuex';
import App from './App.vue'
import router from './router'
import store from './store'
// eslint-disable-next-line no-unused-vars
import axios from 'axios'
import './plugins/element.js'
/*import './styles/styl/base.styl';*/
// eslint-disable-next-line no-unused-vars
import { post, fetch, patch, put } from './utils/http'
// eslint-disable-next-line no-unused-vars,import/no-duplicates
import { Message } from 'element-ui'
// eslint-disable-next-line import/no-duplicates,no-unused-vars
import { Loading } from 'element-ui'
import  ElementUi  from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUi)
// eslint-disable-next-line import/no-duplicates,no-unused-vars
import { MessageBox } from 'element-ui'

// 引入codemirror
import { codemirror } from 'vue-codemirror'
// 引入配置对应的文件（样式、主题、代码格式等）
import './utils/cm-setting.js'

// 导入全局样式
import '@/assets/scss/index.scss'
import './assets/css/global.css'
// 注册使用
Vue.component("codemirror", codemirror);


/*import './icons/iconfont.css'*/

Vue.prototype.$axios = axios


import moment from 'moment'; //导入模块
moment.locale('zh-cn'); //设置语言 或 moment.lang('zh-cn');
Vue.filter("format", function(input, fmtstring) {
  // 使用momentjs这个日期格式化类库实现日期的格式化功能
  return moment(input).format(fmtstring);
});


import * as echarts from 'echarts';// 全局过滤器
import * as filters from './filters'
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
Vue.prototype.$echarts = echarts


import httpRequest from '@/utils/httpRequest' // api: https://github.com/axios/axios
import { isAuth } from '@/utils'
// 挂载全局
Vue.prototype.$http = httpRequest // ajax请求方法
Vue.prototype.isAuth = isAuth     // 权限方法



Vue.prototype.$moment = moment;
Vue.config.productionTip = false
// 定义全局变量
Vue.prototype.$post = post
Vue.prototype.$fetch = fetch
Vue.prototype.$patch = patch
Vue.prototype.$put = put
//Vue.prototype.$confirm = MessageBox.confirm
//Vue.prototype.$message = Message

// 设置浏览器标题
Vue.directive('title', {
  inserted: function (el, binding) {
    document.title = el.dataset.title
  }
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
