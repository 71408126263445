<template>
    <div>
        <dk-title name="设备详情"></dk-title>
        <el-card>
            <div slot="header">
                <span class="header-txt">{{ modelDetail.model }} <span style="color: #0f0;">{{ modelDetail.status }}</span></span>
            </div>
            <el-form :model="modelDetail">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="产品ID">
                            {{ modelDetail.modelId }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="设备数量">
                            {{ modelDetail.num }}
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建时间">
                            {{ modelDetail.createTime }}
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-steps :active="active" finish-status="success" simple>
            <el-step title="物模型定义" @click.native="changeTab(0)"></el-step>
            <el-step title="Topic列表" @click.native="changeTab(1)"></el-step>
            <el-step title="设备管理" @click.native="changeTab(2)"></el-step>
        </el-steps>
        <div v-if="active==0">
            <el-button @click="handleImportModel">导入物模型</el-button>
            <el-button @click="handleViewModelJson">查看物模型JSON</el-button>
        </div>
        <div v-if="active==1">
            <el-table :data="topicList" style="margin-top: 20px;" :header-cell-style="{background:'#F2F3F5', color:'#000000', fontFamily: 'Microsoft YaHei'}">
                <el-table-column label="Topic权限" prop="TopicName"></el-table-column>
                <el-table-column label="操作权限" prop="Privilege">
                    <template slot-scope="scope">
                        {{ scope.row.Privilege==1? "发布" : "订阅"}}
                    </template>
                </el-table-column>
                <el-table-column label="备注" prop="Comment"></el-table-column>
            </el-table>
        </div>
        <div v-if="active==2">
            <el-table :data="deviceList" style="margin-top: 20px;" :header-cell-style="{background:'#F2F3F5', color:'#000000', fontFamily: 'Microsoft YaHei'}">
                <el-table-column label="设备名称" prop="describes"></el-table-column>
                <el-table-column label="状态" prop="Privilege">
                    <template slot-scope="scope">
                        {{ scope.row.Privilege==1}}
                    </template>
                </el-table-column>
                <el-table-column label="创建时间" prop="creattime"></el-table-column>
            </el-table>
        </div>
        
    </div>
</template>
<script>
import dkTitle from '../../components/dk-title.vue'

export default {
    name: 'model-detail',
    components: { dkTitle },
    data(){
        return {
            modelDetail: {},
            modelId: '',
            active: 0,
            importModelDialog: false,
            viewModelJsondialog: false,
            topicList: [{
                TopicName: "$thing/up/property/WTBG0PTGSI/${deviceName}",
                Comment: "属性上报",
                Privilege: 1
            },{
                TopicName: "$thing/down/property/WTBG0PTGSI/${deviceName}",
                Comment: "属性下发与属性上报响应",
                Privilege: 2
            },{
                TopicName: "$thing/up/event/WTBG0PTGSI/${deviceName}",
                Comment: "事件上报",
                Privilege: 1
            }],
            deviceList:[{
                sn: "bbb1",
                describes: "设备-一楼-773301",
                productID: "综合物联网关",
                operator: "ylj",
                organization: "上海电科",
                creattime: "2024-06-30 13:50:53"
            },{
                sn: "bbb2",
                describes: "设备-9号楼3楼-test2",
                productID: "共享工位屏",
                operator: "ylj",
                organization: "上海电科",
                creattime: "2024-06-30 13:50:53"
            },{
                sn: "bbb3",
                describes: "设备-9号楼2楼事业部-test3",
                productID: "共享工位屏",
                operator: "ylj",
                organization: "上海电科",
                creattime: "2024-06-30 13:50:53"
            }]
        }
    },
    methods: {
        hideDialog(){
            this.importModelDialog = false,
            this.viewModelJsondialog = false
        },
        handleImportModel(){
            this.importModelDialog = true
        },
        handleViewModelJson(){
            this.viewModelJsondialog = true
        },
        getModelDetail(id){
            this.modelDetail = {
                model: '综合物联网关',
                operator: 'ylj',
                modelId: '0AFC3GTKEW',
                status: '开发中',
                num: 2,
                describes: '-',
                createTime: '2024-03-12 09:00:54'
            }
        },
        changeTab(val){
            console.log('changeTab~'+val)
            this.active = val
            console.log(this.active)
        },
        
    },
    mounted() {
        this.modelId = this.$route.query.modelId
        this.getModelDetail(this.modelId)
    }
}
</script>
<style scoped>
.header-txt{
  font-weight: 700;
  font-size: 14px;
}
</style>