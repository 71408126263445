<template>
    <div>
        <dk-title name="物模型定义帮助"></dk-title>
        <div>模型JSON示例：</div>
        <div>
        <p>{</p>
        <p style="text-indent: 2rem;">"properties": [</p>
        <p style="text-indent: 4rem;">{</p>
        <p style="text-indent: 6rem;">"id": "power_switch",</p>
        <p style="text-indent: 6rem;">"name": "电灯开关",</p>
        <p style="text-indent: 6rem;">"desc": "控制电灯开灭",</p>
        <p style="text-indent: 6rem;">"mode": "rw",</p>
        <p style="text-indent: 6rem;">"define": {</p>
        <p style="text-indent: 8rem;">"type": "bool",</p>
        <p style="text-indent: 8rem;">"desc":"0 / 1",</p>
        <p style="text-indent: 8rem;">"unit":""</p>
        <p style="text-indent: 6rem;">}</p>
        <p style="text-indent: 4rem;">},</p>
        <p style="text-indent:4rem;">{</p>
            <p style="text-indent: 6rem;"> "id": "brightness",</p>
            <p style="text-indent: 6rem;">    "name": "亮度",</p>
            <p style="text-indent: 6rem;">"desc": "灯光亮度",</p>
            <p style="text-indent: 6rem;">"mode": "rw",</p>
            <p style="text-indent: 6rem;">"define": {</p>
                <p style="text-indent: 8rem;">    "type": "int",</p>
                <p style="text-indent: 8rem;">   "desc":"整数",</p>
                <p style="text-indent: 8rem;">    "unit": "%"</p>
                <p style="text-indent: 6rem;">}</p>
                <p style="text-indent: 4rem;">},</p>
                <p style="text-indent: 4rem;">{</p>
                    <p style="text-indent: 6rem;">"id": "color_temp",</p>
                    <p style="text-indent: 6rem;">"name": "色温",</p>
                    <p style="text-indent: 6rem;">"desc": "灯光冷暖",</p>
                    <p style="text-indent: 6rem;">"mode": "rw",</p>
                    <p style="text-indent: 6rem;">"define": {</p>
                        <p style="text-indent: 8rem;">     "type": "float",</p>
                        <p style="text-indent: 8rem;">     "desc":"浮点数",</p>
                        <p style="text-indent: 8rem;">     "unit": "K"</p>
                        <p style="text-indent: 6rem;">}</p>
                        <p style="text-indent: 4rem;">},</p>
                        <p style="text-indent: 4rem;">{</p>
                            <p style="text-indent: 6rem;">"id": "gradient_cycle",</p>
                            <p style="text-indent: 6rem;">"name": "渐变时间",</p>
                            <p style="text-indent: 6rem;">"desc": "",</p>
                            <p style="text-indent: 6rem;">"mode": "r",</p>
                            <p style="text-indent: 6rem;">"define": {</p>
                                <p style="text-indent: 8rem;">   "type": "timestamp",</p>
                                <p style="text-indent: 8rem;">    "desc":"INT类型的UTC时间戳",</p>
                                <p style="text-indent: 8rem;">    "unit": "秒"</p>
                                <p style="text-indent: 6rem;">}</p>
                                <p style="text-indent: 4rem;">},</p>
                                <p style="text-indent: 4rem;">{</p>
                    <p style="text-indent: 6rem;">"id": "night_light",</p>
                    <p style="text-indent: 6rem;">"name": "夜灯设置",</p>
                    <p style="text-indent: 6rem;">"desc": "",</p>
                    <p style="text-indent: 6rem;"> "mode": "rw",</p>
                    <p style="text-indent: 6rem;"> "define": {</p>
                <p style="text-indent: 8rem;">    "type": "string",</p>
                <p style="text-indent: 8rem;">     "desc":" 字符串长度小于512字节",</p>
                <p style="text-indent: 8rem;">     "unit": ""</p>
                <p style="text-indent: 6rem;">}</p>
                <p style="text-indent: 4rem;"> }</p>
                <p style="text-indent: 2rem;">]</p>
                <p>}</p>
        </div>
    </div>
</template>
<script>
import dkTitle from '../../components/dk-title.vue'
export default {
    name: "help",
    components: { dkTitle },
    data(){
        return{
            

        }
    }
}
</script>