<template>
<div>
  <div class="tableBox">
    <dk-title name="产品列表"></dk-title>
    <el-button
      style="float: right; margin-top: 1%; background-color: #0076F6; color: #fff"
      size="mini"
      @click="openAddDialog"
      icon="el-icon-plus">
      新增
    </el-button>
    <el-form :model="searchData" inline ref="searchForm">
      <el-form-item label="产品名称：" prop="describes">
        <el-input style="width: 200px" size="mini" placeholder="支持模糊查询" v-model="searchData.describes"></el-input>
      </el-form-item>
      <el-form-item label="产品类型：" prop="type">
        <el-input style="width: 200px" size="mini" placeholder="支持模糊查询" v-model="searchData.type"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button style="margin-left: 20px; background-color: #0076F6; color: #fff" size="mini" @click="getModelList" icon="el-icon-search">搜索</el-button>
        <el-button style="margin-left: 20px;" size="mini" @click="resetSearch('searchForm')">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="table" style="height: 700px; overflow: auto">
      <el-table :default-sort = "{prop: 'id', order: 'ascending'}" :data="list" style="width: 100%; margin-top: 20px" :header-cell-style="{background:'#F2F3F5', color:'#000000', fontFamily: 'Microsoft YaHei'}">
        <el-table-column
          align="left"
          fixed
          prop="ID"
          label="产品ID"
          sortable
          width="150">
          <template slot-scope="scope">
            <span class="model-name" @click="goModelDetail(scope.row)">{{ scope.row.ID }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="left"
          fixed
          prop="describes"
          label="产品名称"
          sortable
          width="150">
          <template slot-scope="scope">
            <span style="margin-left: 10px;">
              {{ scope.row.describes }}
            </span>
          </template>
        </el-table-column>
        
        <el-table-column
          align="center"
          fixed
          prop="type"
          label="产品类型"
          sortable
          width="150">
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.type }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          fixed
          prop="organization"
          label="所属组织"
          sortable
          fit>
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.organization }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          fixed
          prop="creattime"
          label="创建时间"
          sortable
          fit>
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.creattime | dateTimeFormat }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          fixed
          prop="operator"
          label="操作者"
          sortable
          fit>
          <template slot-scope="scope">
            <span style="margin-left: 10px">{{ scope.row.operator }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="operation"
          label="操作"
          fixed="right"
          width="150px"
          fit>
          <template slot-scope="scope">
            <!-- <span style="color: #409EFF; cursor: pointer; font-size: 12px; margin-right: 10%" @click="openEditDialog(scope.$index, scope.row)">编辑</span> -->
            <span style="color: #409EFF; cursor: pointer; font-size: 12px; margin-right: 10%" @click="delModel(scope.$index, scope.row)">删除</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>

  <!--    新增产品-->
  <el-dialog
    append-to-body
    title="新增产品"
    :visible.sync="addDialog"
    width="30%">
    <el-form :model="addForm" ref="addForm" :rules="addFormRules">
      <el-form-item label="产品类型" :label-width="formLabelWidth" prop="type">
        <el-input v-model="addForm.type" size="mini" style="width: 200px"></el-input>
      </el-form-item>
      <el-form-item label="产品名称" :label-width="formLabelWidth" prop="describes">
        <el-input v-model="addForm.describes" size="mini" style="width: 200px"></el-input>
      </el-form-item>
      <el-form-item label="操作者" :label-width="formLabelWidth" prop="operator">
        <el-input v-model="addForm.operator" size="mini" style="width: 200px" disabled></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="mini" @click="addDialog = false">取 消</el-button>
      <el-button size="mini" type="primary"  @click="confirmAdd('addForm')">确 定</el-button>
    </span>
  </el-dialog>

<!--  编辑产品-->
  <el-dialog
    append-to-body
    title="编辑产品"
    :visible.sync="editDialog">
    <el-form :model="editForm" ref="editForm" :rules="editFormRules">
      <el-form-item label="产品类型" :label-width="formLabelWidth" prop="type">
        <el-input v-model="editForm.type" size="mini" style="width: 200px"></el-input>
      </el-form-item>
      <el-form-item label="产品名称" :label-width="formLabelWidth" prop="describes">
        <el-input v-model="editForm.describes" size="mini" style="width: 200px"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="editDialog = false">取 消</el-button>
        <el-button size="mini" type="primary"  @click="confirmEdit('editForm')">确 定</el-button>
      </span>
  </el-dialog>
</div>
</template>

<script>
import dkTitle from '../../components/dk-title.vue'
export default {
  name: "modelManage",
  components: { dkTitle },
  data(){
    return{
      // searchData: '', // 搜索字段
      searchData: {
        describes:'',
        type:''
      },
      show: false,
      list: [],

      formLabelWidth: '120px',
      addDialog: false,
      addForm:{
        type: '',
        describes: '',
        operator: localStorage.getItem('userName')
      },
      addFormRules:{
        type: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
        describes: [{ required: true, message: '此项不能为空', trigger: 'blur' }]
      },

      editDialog: false,
      editForm:{
        type:'',
        describes: '',
        ID: ''
      },
      editFormRules:{
        type: [{ required: true, message: '此项不能为空', trigger: 'blur' }],
        describes: [{ required: true, message: '此项不能为空', trigger: 'blur' }]
      },
      organization: '', // 所属组织

    }
  },

  mounted() {
    this.organization = JSON.parse(localStorage.getItem('organization')).organization || ''
    this.getModelList()
  },

  methods:{
    // 重置搜索条件
    resetSearch(formName){
      this.$refs[formName].resetFields();
    },
    goModelDetail(row){
      console.log(row)
      this.$router.push({
        path: '/modelDetail',
        query: {
          ID: row.ID
        }
      })
    },
    //获取模型列表
    getModelList(){
      let query1 = ''
      let query2 = ''
      let query=''
      query1 =  this.searchData.type ? "type like "+"'%"+this.searchData.type+"%'" : ""
      query2 =  this.searchData.describes ? "describes like "+"'%"+this.searchData.describes+"%'" : ""
      if(query1!=='' && query2 !== ''){
        query = 'where '+query1+ ' && ' + query2
      }else if(query1!=='' && query2 == ''){
        query = 'where '+query1
      }else if(query1=='' && query2 !== ''){
        query = 'where '+query2
      }else{
        query = ''
      }
      console.log(query)

      let data={
        "sql": `SELECT * FROM product_table ${query} order by creattime desc`,
        "type": "select"
      }
      this.$axios.post('/sql/excute', data).then(res => {
          if (res.data.code === 200) {
            this.list=res.data.data
          } else {}
        }
      ).catch(error => {
        console.log('错误' + error)
      })
    },

    //打开新增弹框
    openAddDialog(){
      // this.addForm.model=''
      this.addForm.describes=''
      this.addForm.type=''
      this.addDialog=true
    },

    /**
     * 用于随机生成产品的ID
     * @param {*} length 生成的随机数的长度
     */
    generateRandomCode(length) {
			let code = '';
			const chars = '0123456789ACDEFGHJKLMNPQRSTUVWXYZ'; // 可以根据需要添加更多字符
			const charsLength = chars.length;
			for (let i = 0; i < length; i++) {
				code += chars.charAt(Math.floor(Math.random() * charsLength));
			}
			return code;
		},
    confirmAdd(formName){
      let ID = ''
      ID = this.generateRandomCode(10)
      let data = {
        "sql": `INSERT INTO product_table (ID, describes,operator,organization,type)VALUES('${ID}','${this.addForm.describes}','${this.addForm.operator}','${this.organization}','${this.addForm.type}')`,
        "type": "insert"
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios.post('/sql/excute', data).then(res => {
              if (res.data.code === 200) {
                this.addDialog=false
                this.getModelList()
              } else {
                this.$message.error(res.data.message)
              }
            }
          ).catch(error => {
            if(error.response.data.message.indexOf('Duplicate')!=-1){
              this.$message.error('模型名称已存在')
            }
          })
        } else {
          return false;
        }
      })
    },

    //删除模型
    delModel(index, row){
      this.$confirm("永久删除此项, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
      let data={
        "sql": "DELETE FROM product_table WHERE ID="+"'"+row.ID+"'",
        "type": "delete"
      }
      this.$axios.post('/sql/excute', data).then(res => {
          if (res.data.code === 200) {
            this.delPoint(row.model)
            this.$message.success('删除成功')
            this.getModelList()
          } else {}
        }
      ).catch(error => {
        console.log('错误' + error)
      })
        }).catch(err => {
        this.$message.error('服务器失败' + err)
      })
    },
    //删除物模型同时要删除旗下测点
    delPoint(model){
      let data={
        "sql": "DELETE FROM pointTable WHERE model="+"'"+model+"'",
        "type": "delete"
      }
      this.$axios.post('/sql/excute', data).then(res => {
          if (res.data.code === 200) {

          } else {}
        }
      ).catch(error => {
        console.log('错误' + error)
      })
    },

    //打开编辑弹框
    openEditDialog(index, row){
      this.editDialog=true
      this.editForm.model=row.model
      this.editForm.model2=row.model
      this.editForm.describes=row.describes
      this.editForm.describes2=row.describes
    },
    confirmEdit(formName){
      let data = {
        "sql": "UPDATE product_table SET describes = "+"'"+ this.editForm.describes+"'" +", type = "+"'"+ this.editForm.type+"'" +" WHERE ID="+"'"+this.editForm.ID+"'",
        "type": "update"
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios.post('/sql/excute', data).then(res => {
              if (res.data.code === 200) {
                this.editDialog=false
              } else {}
            }
          ).catch(error => {
            console.log('错误' + error)
          })
        } else {
          return false;
        }
      })
    },
  },
}
</script>

<style scoped>
.model-name{
  color: #409EFF;
}
.model-name:hover{
  color: #409EFF;
  text-decoration: underline;
  cursor: pointer;
}
</style>
