import axios from 'axios'
// eslint-disable-next-line no-unused-vars
import { Message, MessageBox, Loading } from 'element-ui'
axios.defaults.timeout = 5000
// 默认的地址
axios.defaults.baseURL = ''
// http request 拦截器
axios.interceptors.request.use(
  config => {
    console.log('http request 拦截器~')
    console.log(config.headers)
    const token = window.localStorage.getItem('token')
    if(config.headers.Accept=='application/csv'){
      return config
    }else if(config.headers.Authorization=='Basic ZjExZDI4Y2I0OGNmZjQ2NzpyY1ZpYTZRcFE0VWZDb0lUSzhaUVVyRzlBemFWUk1UQnowTXk3cEZld1E5Q0o='){
      return config
    }else{
      if (token != null) {
        config.headers.Authorization = 'Bearer '+token
        config.headers.username='dkzn'
        config.headers.password='dkzn123'
      } else {
        config.headers.Authorization = 'Bearer '
        config.headers.username='dkzn'
        config.headers.password='dkzn123'
      }
    }

    return config
  },
  // eslint-disable-next-line handle-callback-err
  error => {
    // eslint-disable-next-line no-undef
    return Promise.reject(err)
  }
)

// http response 拦截器
axios.interceptors.response.use(
  response => {
    console.log('拦截后端返回的状态码')
    // 获取服务器返回拦截当erroCode=2的时候没有登录返回登陆页面
    const code = response.resultCode
    if (code === '100001') {
      window.localStorage.clear()
      MessageBox.alert('身份验证失败，请重新登陆', {
        confirmButtonText: '跳转到登录页面',
        callback: action => {
          window.location.href = '/'
        }
      })
    }
    return response
  },
  error => {
    return Promise.reject(error)
  }
)

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function fetch (url, params = {}, config = {}) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params
    })
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}
/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function get (url, params = {}) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: params,
      responseType: 'blob'
    })
      .then(response => {
        resolve(response.data)
      })
      .catch(err => {
        reject(err)
      })
  })
}
/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post (url, data = {}, config = {
  showLoading: true,
  isUpload: false
}) {
  return new Promise((resolve, reject) => {
    axios.post(url, data)
      .then(response => {
        resolve(response.data)
      }, err => {
        reject(err)
      })
  })
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function patch (url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.patch(url, data)
      .then(response => {
        resolve(response.data)
      }, err => {
        reject(err)
      })
  })
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put (url, data = {}) {
  return new Promise((resolve, reject) => {
    axios.put(url, data)
      .then(response => {
        resolve(response.data)
      }, err => {
        reject(err)
      })
  })
}
