import { isEmpty } from '../utils/index'
const moment = require('moment')
moment.suppressDeprecationWarnings = true

// 格式化时间戳为年月日时分秒
const dateTimeFormat = (timeStamp) => {
    if (isEmpty(timeStamp)) {
        return timeStamp;
    }
    return moment(timeStamp).format('YYYY-MM-DD HH:mm:ss');
};
// 格式化时间戳为年月日
const dateFormat = (timeStamp) => {
    if (isEmpty(timeStamp)) {
        return timeStamp;
    }
    return moment(timeStamp).format('YYYY-MM-DD');
};
// 格式化时间戳为月日
const dateFormatMD = (timeStamp) => {
    if (isEmpty(timeStamp)) {
        return timeStamp;
    }
    return moment(timeStamp).format('MM-DD');
};
// 格式化时间戳为时分秒
const timeFormat = (timeStamp) => {
    if (isEmpty(timeStamp)) {
        return timeStamp;
    }
    return moment(timeStamp).format('HH:mm:ss');
};
// 运营商
const carrierFormat = (level) => {
    const carrierMap = new Map([
        ["cmcc", '移动'],
        ["chinanet", '电信'],
        ["unicom", '联通']
    ]);
    return carrierMap.get(level);
};
// 物联网卡状态
const statusFormat = (level) => {
    const statusMap = new Map([
        ["testing", '测试中'],
        ["inventory", '库存'],
        ["pending-activation", '待激活'],
        ["activation", '已激活'],
        ["deactivation", '已停卡'],
        ["retired", '已销卡'],
    ]);
    return statusMap.get(level);
};
// 机卡绑定状态
const imeiStatusFormat = (level) => {
    const statusMap = new Map([
        ["NOT_SET", '未开启'],
        ["NOT_BIND", '待绑定'],
        ["NORMAL", '正常'],
        ["SPLIT", '机卡分离']
    ]);
    return statusMap.get(level);
};
// 套餐类型
const packageTypeFormat = (level) => {
    const packageTypeMap = new Map([
        ["monthly", '月度标准套餐'],
        ["customer", '自定义套餐'],
        ["package", '加油包'], // 只有月度标准套餐才能使用加油包
    ]);
    return packageTypeMap.get(level);
};
// 套餐状态
const packageStatusFormat = (level) => {
    const packageTypeMap = new Map([
        ["active", '当前套餐'],
        ["finished", '历史套餐'],
        ["plan", '计划套餐'], // 只有月度标准套餐才能使用加油包
    ]);
    return packageTypeMap.get(level);
};
// 卡类型
const cardTypeFormat = (level) => {
    const cardTypeMap = new Map([
        ["SINGLE", '单卡'],
        ["POOL", '流量池卡']
    ]);
    return cardTypeMap.get(level);
};
// 周期单位
const timeUnitFormat = (level) => {
    const timeUnitMap = new Map([
        ["month", '月'],
        ["day", '天']
    ]);
    return timeUnitMap.get(level);
};
// 订单类型
const orderTypeFormat = (level) => {
    const orderTypeMap = new Map([
        ["open", '初始购卡'],
        ["renew", '套餐续费'],
        ["overlying", '流量加油包']
    ]);
    return orderTypeMap.get(level);
};
// 物模型数据类型
const modelDataTypeFormat = (level) => {
    const modelDataTypeMap = new Map([
        ['bool','布尔型'],
        ['int','整数型'],
        ['float','浮点型'],
        ['timestamp','时间型'],
        ['string','字符串']
    ]);
    return modelDataTypeMap.get(level);
};
// 读写类型
const modeTypeFormat = (level) => {
    const modeTypeMap = new Map([
        ['r','只读'],
        ['rw','读写']
    ]);
    return modeTypeMap.get(level);
};


export {
    dateTimeFormat,
    dateFormatMD,
    dateFormat,
    timeFormat,
    carrierFormat,
    statusFormat,
    imeiStatusFormat,
    packageTypeFormat,
    packageStatusFormat,
    cardTypeFormat,
    timeUnitFormat,
    orderTypeFormat,
    modelDataTypeFormat,
    modeTypeFormat
}
