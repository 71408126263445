<template>
    <el-drawer
        title="产品信息"
        :visible.sync="visible"
        >
        <el-form :model="form" ref="form" :rules="rules" label-width="100px">
            <el-form-item label="产品ID：" :label-width="formLabelWidth" prop="ID">
                {{form.ID}}
            </el-form-item>
            <el-form-item label="产品类型：" :label-width="formLabelWidth" prop="type">
                {{form.type}}
            </el-form-item>
            <el-form-item label="产品名称：" :label-width="formLabelWidth" prop="describes">
                {{form.describes}}
            </el-form-item>
            <el-form-item label="创建时间：" :label-width="formLabelWidth" prop="creattime">
                {{form.creattime | dateTimeFormat}}
            </el-form-item>
            <el-form-item label="更新时间：" :label-width="formLabelWidth" prop="updatetime">
                {{form.updatetime | dateTimeFormat}}
            </el-form-item>
            <div class="btns_container">
                <el-button class="bottom_btn" type="primary" size="medium" @click="handleModi">修改产品信息</el-button>
            </div>
        </el-form>
    </el-drawer>
</template>
<script>

export default {
    name: "view-product-detail-drawer",
    props: {
        showDialog:{
            type: Boolean,
            default: false
        },
        info:{
            type: Object,
            default: () => {}
        }
    },
    data(){
        return {
            ID: '',
            model: {},
            form: {
                name: '', // 名称
                describes:'',
                ID:'',
                creattime:'',
                updatetime:''
            },
            rules: {
                name:[
                    {required: true, message: '请输入功能名称', trigger: 'blur'}
                ],
                
            },
            visible: this.showDialog,
            options: {
                line: true,
                theme: "rubyblue", // 主题
                tabSize: 4, // 制表符的宽度
                indentUnit: 2, // 一个块应该缩进多少个空格（无论这在编辑语言中意味着什么）。默认值为 2。
                firstLineNumber: 1, // 从哪个数字开始计算行数。默认值为 1。
                readOnly: false, // 只读
                autorefresh: true,
                smartIndent: true, // 上下文缩进
                lineNumbers: true, // 是否显示行号
                styleActiveLine: true, // 高亮选中行
                viewportMargin: Infinity, //处理高度自适应时搭配使用
                showCursorWhenSelecting: true, // 当选择处于活动状态时是否应绘制游标
                mode: "application/json",
                lineWrapping: true, //自动换行
                lint: false, // 开启语法检查
                gutters: ['CodeMirror-lint-markers'],  // 语法检查器
            },
            typeList:[{
                name: '布尔型',
                value: 'bool'
            },{
                name: '整数型',
                value: 'int'
            },{
                name: '浮点型',
                value: 'float'
            },{
                name: '时间型',
                value: 'timestamp'
            },{
                name: '字符串',
                value: 'string'
            }],
            modeList:[{
                name: '读写',
                value: 'rw'
            },{
                name: '只读',
                value: 'r'
            }],
            operator: localStorage.getItem('userName'),
            organization: JSON.parse(localStorage.getItem('organization')).organization
        }
    },
    methods: {
        handleModi(){
            this.$emit('edit')
        },
        handleTypeChange(val){
            console.log(val)
            this.form.define.unit = val=='timestamp' ? '秒':''
        },
        submitForm(){
            console.log(this.form)
            console.log(this.model)
            if(this.propertyId===''){
                this.model.properties.unshift(this.form)
            }else{
                this.model.properties.forEach(item => {
                    if(item.id===this.propertyId){
                        item = this.form
                    }
                })
            }
            
            console.log(this.model)
            let model = ''
            // model = this.form.jsonContent.replace(/\n/g, '').replace(/\s+/g, '');
            model = JSON.stringify(this.model)
            console.log(model)
            let data={
                sql: `UPDATE product_table SET operator='${this.operator}',organization='${this.organization}',model='${model}' WHERE ID='${this.ID}'`,
                type: 'update'
            }
            console.log(data)
            this.$axios.post('/sql/excute', data).then(res => {
                if (res.data.code === 200) {
                    this.$message.success('添加属性成功！')
                    this.handleClose()
                    this.$emit('refresh')
                } else {}
            }).catch(error => {
                console.log('错误' + error)
            })
        },
        /**
         * 表单提交按钮监听
         */
        handleConfirm(formName) {
            console.log(this.form)
            this.$refs[formName].validate((valid) => {
                console.log(valid)
                if (valid) {
                    this.submitForm();
                } else {
                    this.$message.warning('校验失败，请务必填写*标记内容')
                    return false
                }
            })
        },
        handleCancel(formName){
            this.handleClose()
        },
        /**
       * 关闭弹框
       */
      handleClose(){
        // this.initData();
        this.$refs['form'].resetFields()
        this.visible = false;
        this.$emit('close');
      },
    },
    watch:{
        showDialog(){
            this.visible = this.showDialog
        },
        visible(val, oldVal){
            if(!val){
                this.handleClose()
            }else{
                this.form = this.info
            }
        }
    }
}
</script>
<style scoped>
.btns_container{
    margin-top: 20px;
    text-align: center;
}
.tip-txt{
    font-size: 14px;
    margin: 20px 0px;
}
/*  */
</style>