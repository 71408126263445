import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/common/login'
import main from '../views/main'
import home from '../views/common/home'
import deviceManage from '../views/common/deviceManage'
import deviceAdd from '../views/deviceManage/deviceAdd'
import deviceDetail from '../views/deviceManage/deviceDetail'
import theme from '../views/common/theme'
import modelManage from "@/views/modelManage/modelManage"
import modelDetail from "@/views/modelManage/modelDetail"
import ruleManage from "@/views/ruleManage/ruleManage"
// import historyData from "@/views/historyData/historyData"
import Help from "@/views/help/Help"
Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'login', component: login },
  { path: '/main', name: 'main', component: main },

  {
    path: '/home',
    name: 'home',
    component: main,
    children:[
      {path: '/', component: home, name:'首页', meta: {title: '首页', isTab: true}},
      {path: '/deviceManage', component: deviceManage, name:'设备管理', meta: {title: '设备管理', isTab: true}},
      {path: '/modelManage', component: modelManage, name:'产品管理', meta: {title: '产品管理', isTab: true}},
      // {path: '/historyData', component: historyData, name:'历史数据', meta: {title: '历史数据', isTab: true}},
      {path: '/deviceAdd', component: deviceAdd, name:'新增设备', meta: {title: '新增设备', isTab: true}},
      {path: '/modelDetail', component: modelDetail, name:'产品详情', meta: {title: '产品详情', isTab: true}},
      {path: '/deviceDetail', component: deviceDetail, name:'设备详情', meta: {title: '设备详情', isTab: true}},
      {path: '/help', component: Help, name:'帮助', meta: {title: '帮助', isTab: true}},
    ]
  },

  {
    path: '/theme',
    name: 'theme',
    component: main,
    children:[
      {path: '/', component: theme, name:'设置', meta: {title: '设置'}}
    ]
  },



]



const router = new VueRouter({
  routes,

})
// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
  // to 将要访问的路径
  // form 代表从哪个路跳转
  // next 函数 表示放行一个连接
  // next() 放行 next('/login') 强制跳转
  if (to.path === '/login') return next()
  // 获取token
  // eslint-disable-next-line no-unused-vars
  const tokenStr = window.localStorage.getItem('token')
  /*if (!tokenStr) return next('/')*/
  // eslint-disable-next-line no-unreachable
  next()
})
export default router
